import React, {useState} from "react";
import {IoTicket,IoCalendarOutline,IoTimeOutline} from 'react-icons/io5';
const Events = (props) => {
    const [pastEvents, setPastEvents] = useState(false);
    const [pastYear, setPastYear] = useState(new Date().getFullYear());
    const [allYears, setAllYears] = useState([]);

  /* The following function returns the first 10 upcoming events from your Google Calendar, and filters out the finished events.
     The switch deals with two cases, where the second one, true, returns ALL the next calendar events.*/
  let reduceTo10events = (e) => {
    let temp = [];
    for(let i of e.events)
    i && temp.push(i);
    return [...temp];
  }
    /* The following .map method returns some of the object parameters from the Google Calendar Event, which is retrieved thorugh an Ajax call
       in the Calendar component. Now, it returns the data in a presentable manner, which makes css styling a breeze. The .map method retrieves the parameters
       for all the Calendar Events. */
    /* props.savedState fetches the saved return from the Ajax call in the Calendar component. */

    let events = (props.savedState.items || []).map((item, index) => {
      let summary = item.summary;
      let description = item.description?.indexOf('|') ? item.description.split('|',3) : [item.description];
      let location = item.location;
      let start = item.start.dateTime;
      let number = [...start];
      let year = new Date(item.start.dateTime).getFullYear();
      let descriptionText = description ? description[0] : '';
      let orchestra = description ? description[1] : '';
      let conductor = description ? description[2] : '';

      if(start.slice(0,15) < new Date().toJSON().slice(0,15) ) {
        if(allYears === null && allYears.length <= 0){
          setAllYears([year])
        } else if(allYears.includes(year) === true) {
        } else {
          let allTheYears = [year, ...allYears];
          allTheYears.sort((a,b) => b-a);
          setAllYears(allTheYears)
        }
      }
      /* If the events' start time is later than today, it will return the events */

      if(item.start.dateTime.slice(0,15) > new Date().toJSON().slice(0,15) && pastEvents === false ) {
          return (
            <div className="eventsContainer" key={`${summary}${number}`}>
                <div className="timeContainer flex column">
                  <h2 className="date"><IoCalendarOutline/>{`${number[8]}${number[9]}.${number[5]}${number[6]}.${number[2]}${number[3]}`}<IoTimeOutline/>{`${number[11]}${number[12]}${number[13]}${number[14]}${number[15]}`}</h2>
                </div>
                <div className="descriptionContainer">
                  <h2 className={`summary ${summary.includes("cancelled") ? ' cancelled' : ''}`}>{summary}</h2>
                  {orchestra ? <h3>{orchestra}</h3> : ""}
                  {conductor ? <h4>{conductor}</h4> : ""}
                  {descriptionText ? <h4 className="description"> {descriptionText}</h4> : ""}
                  {
                      location ? <a href={location} className="location btn--inverse" target="_blank" rel="noreferrer" role="button"><IoTicket size={24} /></a> : ''
                  }
                </div>
                </div>
            )
          } else if(item.start.dateTime.slice(0,15) < new Date().toJSON().slice(0,15) && pastEvents === true  && year === pastYear) {
              return (
                <div className="eventsContainer" key={`${summary}${number}`}>
                    <div className="timeContainer flex column">
                      <h2 className="date"><IoCalendarOutline />{`${number[8]}${number[9]}.${number[5]}${number[6]}.${number[2]}${number[3]}`}</h2>
                    </div>
                    <div className="descriptionContainer">
                      <h2 className={`summary ${summary.includes("cancelled") ? ' cancelled' : ''}`}>{summary}</h2>
                      {orchestra ? <h3>{orchestra}</h3> : ""}
                      {conductor ? <h4>{conductor}</h4> : ""}
                      {descriptionText ? <h4 className="description"> {descriptionText}</h4> : ""}
                    </div>
                    </div>
                )
              }
        }
      )
    return (
      <div className="events">
        <div className="flex center buttons">
          {/*<div className={`btn${pastEvents === false ? "--active" : "--inverse"}`} onClick={(()=>setPastEvents(false))}><h3>Future</h3></div>
          <div className={`btn${pastEvents === true ? "--active" : "--inverse"}`} onClick={(()=>setPastEvents(true))}><h3>Past</h3></div> */}
          <label className="switch">
          <input type="checkbox" id="togBtn" default="on"
            onClick={(()=>setPastEvents(!pastEvents))}
          / >
          <div className="slider round">
            <span className="on">Past</span>
            <span className="off">Future</span>
            </div>
          </label>
        </div>
        
        <div className={`flex center buttons ${pastEvents ? 'visible' : 'invisible'}`}>
        {
          allYears.map(x=>{
            // filter out future years
            if(x <= new Date().getFullYear()){
                return (<div key={x} className={`btn${x === pastYear ? "--active" : "--inverse"}`} onClick={(()=>setPastYear(x))}><h3>{`${x}`}</h3></div>)
            } else {
              return(<div></div>)
            }
          })
        }
        </div>
        <div className="eventsWrapper">
          {reduceTo10events({events})}
        </div>
      </div>
    );
  }
export default Events;
