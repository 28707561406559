import React from 'react'
import CalendarContainer from "../components/CalendarContainer"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Calendar = ({location}) => {
  return (
    <Layout location={location} title="About Magnus">
      <Seo title="Calendar" />
        <CalendarContainer />
    </Layout>
  )
}
export default Calendar;
